<template>
  <div>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">Trang chủ</li>
      <li class="breadcrumb-item"><a href=""> Quản lý Tuyến xe </a></li>
    </ol>
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-2">
        <div class="form-group">
          <router-link to="/admin/vehicle/add" class="btn btn-sm btn-primary">
            <i class="fas fa-plus"></i>
            Thêm mới Tuyến xe
          </router-link>
        </div>
      </div>
      <div class="col-sm-1" v-if="is_loading">
          <MyLoading></MyLoading>
      </div>
     <div class="col-sm-1" v-else>
        <div class="form-group">

          <xlsx-workbook>
            <xlsx-sheet
              
              v-for="sheet in sheets"
              :key="sheet.name"
              :sheet-name="sheet.name"
              :collection="sheet.data"
            />
            <xlsx-download :filename="sheetName">
              <button  class="btn btn-sm btn-success">
                 <i class="fas fa-file-export"></i>
            Export 
             
              </button>
            </xlsx-download>
            
          </xlsx-workbook>
            


           
        </div>
      </div>

        <div class="col-sm-1">
        <div class="form-group">

          
            


           <router-link to="/admin/vehicle/import" class="btn btn-sm btn-success ">
             <i class="fas fa-file-import"></i>
            Import  
          </router-link>
        </div>
      </div>

       
      
      </div>
      <div>
        <form method="post" @submit="mySearch" id="my_search">
          <div class="row">
            <div class="col-sm-5 mb-3">
              <input
                type="text"
                name="name"
                class="form-control"
               
                autocomplete="off"
                placeholder="Nhập tên..."

                v-model="formSearch.name"
              />

              <button class="btn btn-sm btn-warning mt-3" type="submit">
                <i class="fa fa-search-plus"></i> TÌM KIẾM
              </button>
            </div>
             
          </div>
        </form>

      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-header" v-if="is_loading">
              <SkeletonTheme color="#e6f3fd" highlight="#eef6fd">
                <Skeleton width="40px" height="40"></Skeleton>
              </SkeletonTheme>
            </div>
            <div class="card-header" v-else>
              <i class="fa fa-align-justify" v-if="mydata != null"></i> Quản lý
              Tuyến xe : ( {{ page_size }} )
            </div>
            <div class="card-block">
              <SkeletonTheme color="#e6f3fd" highlight="#eef6fd">
                <table class="table table-sm">
                  <thead>
                    <tr>
                       
                        <th>Mã tuyến </th>
                      <th>Tên tuyến xe </th>
                      <th>Doanh nghiệp VT </th>
                      <th>Địa điểm   </th>
                      <th>Cự ly (km) </th>
                      <th> Giờ  KH  </th>
                       <th>Giá (VNĐ)  </th>
                    
                      <th>Tuỳ chọn</th>

                    </tr>
                  </thead>
                  <tbody v-if="is_loading">
                    <tr v-for="(el, k) in 30" :key="k">
                      <td v-for="(x,y) in 5" :key="y">
                        <MyLoading  height="40px"></MyLoading>
                      </td>
                      
                    </tr>
                  </tbody>
                  <tbody v-else>




                    <tr
                      v-for="(value, key) in paginationslice(mydata, 30, page)"
                      :Key="key"
                    >
                       
                      <td >
                       {{ value.code }} 
                      </td>
                      <td>
                          {{value.name}}
                      </td>
                      <td> 
                           
                          {{value.idVehicle | ViewVehicle(business)}}
                      </td>
                     
                      <td>
                          {{value.codeLocationDes | ViewLocation(location)}}
                      </td>
                     

                      <td>
                          {{value.km}}
                      </td>
                     

                      <td>
                          {{value.timer}}
                      </td>
                        <td>
                          {{value.price}} -  {{value.price1}} 
                      </td>

                      
                      <td>
                        <div>
                            <router-link
                              class="btn btn-sm btn-success"
                              v-bind:to="'/admin/vehicle/edit/' + value.id"
                            >
                              <i class="fa fa-edit"></i>
                              Sửa
                            </router-link>
                            <div
                              class="btn btn-sm btn-danger click_remove"
                              @click="removeElement(value.id)"
                            >
                              <i class="fa fa-remove"></i> Xoá
                            </div>

                            </div>
                      </td>

                      
                    </tr>
                  </tbody>
                </table>

               <nav v-if="mydata != null && mydata.length > 30">
                  <VuePagination
                    :page-count="Math.ceil(mydata.length / 30)"
                    :page-range="3"
                    :margin-pages="2"
                    :click-handler="changePage"
                    :prev-text="'Prev'"
                    :next-text="'Next'"
                    :container-class="'pagination'"
                    :page-class="'page-item'"
                  >
                  </VuePagination>
                </nav>
              </SkeletonTheme>
            </div>
          </div>
        </div>
        <!--/.col-->
      </div>
    </div>
  </div>
</template>

<script>
import { Skeleton, SkeletonTheme } from "vue-loading-skeleton";
import VuePagination from "vuejs-paginate";
import axios from "axios";


import { XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx";


export default {
  
  data() {
    return {
      is_loading: true,
      form: {},
      mydata: null,
      main:null,
      search: "",
      page: 1,
      id_load: "",
      mycate:null,
      my_url: window.my_url,

      page_size:0,
      is_remove:[],

      isLogin:null,
      formSearch:{
         name:"",
          cate:""
      },
      language: window.my_header.language,
      role:2,
      location:null,
      business:null,

      sheetName:"Tuyen_xe_"+(new Date()).getDate().toString()+(new Date()).getFullYear().toString()+".xlsx",
      sheets: [{ name: "BEN XE MIEN DONG", data: [  {"BUITIN":"TEST"} ] }],
      

       



    };
  },
  components: {
    Skeleton,
    SkeletonTheme,
    VuePagination,
    XlsxWorkbook, 
    XlsxSheet,
     XlsxDownload
  },
  filters:{
    ViewLocation:function(id,location){
        var result= location.filter( (n)=> n.id==id);
        if(result.length){
            return result[0].name;
        }
        return "";


    },
    ViewVehicle:function(id,business){
        var result= business.filter( (n)=> n.id==id);
          if(result.length){
              return result[0].name;
          }
          return "";
    }

  },
  created(){  
      let isLogin= this.$session.get("isLogin");
      this.isLogin=isLogin.permistions.split(",");

      let Customer= this.$session.get("Customer");
      this.role=Customer.isRole;
  },
  mounted() {

   

    let p1= axios.get(window.my_api + "api/location/get-locations", {
                  headers: window.my_header
                })
                .then((res) => {
                  if (res.status == 200) {
                    this.location  = res.data.data;
                 
                  } else {
                    alert("Hệ thống không hoạt động, vui lòng liên hệ với Admin.");
                  }
          });
   let p2=  axios
      .get(window.my_api + "api/vehicle/get-vehicles", {
        headers: window.my_header
      })
      .then((res) => {
        if (res.status == 200) {
          this.mydata=this.main  = res.data.data;
          this.page_size=this.mydata.length;

          

        
            

          
        } else {
          alert("Hệ thống không hoạt động, vui lòng liên hệ với Admin.");
        }
      });


       let p3= axios.get(window.my_api + "api/business/get-businesss", {
                  headers: window.my_header
                })
                .then((res) => {
                  if (res.status == 200) {
                    this.business  = res.data.data;
                 
                  } else {
                    alert("Hệ thống không hoạt động, vui lòng liên hệ với Admin.");
                  }
          });
      Promise.all([p1,p2,p3])
             .then( ()=>{
                
                 this.sheets[0]['data']=[];
                  let DataSheets=[];

                  for (var i = this.mydata.length - 1; i >= 0; i--) {
                    
                    DataSheets.push({
                           "Mã tuyến":this.mydata[i].code,
                          'Tên tuyến xe': this.mydata[i].name,
                          "Địa điểm":this.getLocation(this.mydata[i].codeLocationDes),
                          'Cự ly (km)': this.mydata[i].km == undefined ? "" :this.mydata[i].km  ,
                          'Giờ  KH': this.mydata[i].timer == undefined ? "" :this.mydata[i].timer,
                          'Giá vé': this.mydata[i].price == undefined ? "" :this.mydata[i].price,
                          '':  this.mydata[i].price1 == undefined ? "" :this.mydata[i].price1 ,
                          'Lộ trình': this.mydata[i].route == undefined ? "" :this.mydata[i].route ,

                    });

                  } 

                  this.sheets[0]['data']=DataSheets;

                this.is_loading = false;


             });
  },
  methods: {
    getLocation(value){
        var result= this.location.filter( (n)=> n.id==value);
                      if(result.length){
                          return result[0].name;
                      }
                      return "";
    },
     changePage(e) {
      this.page = e;
    },
    paginationslice(data, pagesize, page) {
      return data.filter((value, n) => {
        return n >= pagesize * (page - 1) && n < pagesize * page;
      });
    },

    mySearch(event) {
      event.preventDefault();
      


      var form =new FormData(document.getElementById("my_search") ) ;

      this.mydata=this.main.filter( (n)=>{
            
            if(form.get("name").length){
              return    n.name.toUpperCase().search(form.get("name").toUpperCase() ) > -1;
            }
           
            return n;


      });

      return false;
     
    },

    removeAll(){
        
        if(this.is_remove.length > 0){
           for (var i = this.is_remove.length - 1; i >= 0; i--) {
            
              this.removeElement(this.is_remove[i]);
            }
        }
        return false;
         
    },
    removeElement(id) {
     if(confirm("Bạn muốn xoá?")){
            this.id_load = id;
            axios
              .delete(window.my_api + "api/vehicle/remove-vehicle", {
                data: { vehicleIds: [id] },
                headers: window.my_header
              })
              
              .then((res) => {
                if (res.status == 200) {
                  alert("Xoá thành công");
                  this.mydata = this.mydata.filter((el) => el.id !== id);
                } else {
                  alert(
                    "Hệ thống không hoạt động, vui lòng liên hệ với Admin."
                  );
                }
                this.id_load = "";
              });
          }
      
    },
  },
};
</script>








